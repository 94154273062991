import {SupportedWixLocales, supportedWixlocales} from 'wix-design-systems-locale-utils'
import memoize from 'lodash/memoize'

const parseLocaleFromBmRaw = (locale?: string): SupportedWixLocales => {
  if (!locale) {
    return 'en'
  }

  // fixes en-gb to en-GB
  const [language, country] = locale.split('-')
  let fixedLocale = language

  if (country) {
    fixedLocale += `-${country.toUpperCase()}`
  }

  return (
    supportedWixlocales.includes(fixedLocale as SupportedWixLocales) ? fixedLocale : language
  ) as SupportedWixLocales
}

const parseLocaleFromBM = memoize(parseLocaleFromBmRaw)

export {parseLocaleFromBM}
