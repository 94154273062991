import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {Reservation} from '@wix/ambassador-table-reservations-v1-reservation/types'
import omitBy from 'lodash/omitBy'

import {APP_DEF_ID, AppPage} from './constants'

type IsoDateString = string

type ReservationsParams = {
  reservationLocationId?: string
  startDate?: IsoDateString
  partySize?: string
  isTemplateView?: string
}

type ReservationDetailsParams = {isTemplateView?: string} & (
  | {
      reservationId: string
    }
  | {
      startDate: string
      partySize: string
      reservationLocationId: string
    }
)

type ReservationConfirmationParams = {
  reservationId: string
  isTemplateView?: string
}

type AppPageToParamsMap = {
  [AppPage.Reservations]?: ReservationsParams
  [AppPage.ReservationDetails]: ReservationDetailsParams
  [AppPage.ReservationConfirmation]: ReservationConfirmationParams
}

type AppPageToParams<T extends AppPage> = AppPageToParamsMap[T]

export async function goToPage<T extends AppPage>(
  page: T,
  params: AppPageToParams<T>,
  flowAPI: ControllerFlowAPI,
) {
  const {relativeUrl} = await flowAPI.controllerConfig.wixCodeApi.site.getSectionUrl({
    sectionId: page,
    appDefinitionId: APP_DEF_ID,
  })

  const preparedParams = new URLSearchParams(omitBy(params, (p) => p === undefined)).toString()

  flowAPI.controllerConfig.wixCodeApi.location.to?.(`${relativeUrl}?${preparedParams}`)
}

type ReservationDataOptional = Pick<
  Exclude<Reservation['details'], undefined>,
  'reservationLocationId' | 'startDate' | 'partySize'
>

type ReservationData = {
  [P in keyof ReservationDataOptional]-?: NonNullable<ReservationDataOptional[P]>
}

export const goToNewReservation = ({
  flowAPI,
  reservationData,
  isTemplateView,
}: {
  flowAPI: ControllerFlowAPI
  timeZone?: string | null
  reservationData?: ReservationDataOptional
  isTemplateView?: boolean
}) =>
  goToPage(
    AppPage.Reservations,
    {
      reservationLocationId: reservationData?.reservationLocationId ?? undefined,
      startDate: reservationData?.startDate?.toISOString(),
      partySize: reservationData?.partySize?.toString(),
      ...(isTemplateView ? {isTemplateView: 'true'} : {}),
    },
    flowAPI,
  )

export const goToReservationDetails = ({
  flowAPI,
  reservationData,
  reservationId,
  isTemplateView,
}: {
  flowAPI: ControllerFlowAPI
  timeZone?: string | null
  isTemplateView?: boolean
  reservationId?: string | null
  reservationData?: ReservationData
}) => {
  if (reservationId) {
    return goToPage(
      AppPage.ReservationDetails,
      {reservationId, ...(isTemplateView ? {isTemplateView: 'true'} : {})},
      flowAPI,
    )
  }

  if (reservationData) {
    return goToPage(
      AppPage.ReservationDetails,
      {
        reservationLocationId: reservationData.reservationLocationId,
        startDate: reservationData.startDate.toISOString(),
        partySize: reservationData.partySize.toString(),
        ...(isTemplateView ? {isTemplateView: 'true'} : {}),
      },
      flowAPI,
    )
  }
}

export const goToReservationConfirmation = (
  flowAPI: ControllerFlowAPI,
  reservationId?: string | null,
  isTemplateView?: boolean,
) => {
  if (reservationId) {
    return goToPage(
      AppPage.ReservationConfirmation,
      {reservationId, ...(isTemplateView ? {isTemplateView: 'true'} : {})},
      flowAPI,
    )
  }
}

export const openBmLocationSettingsPage = (metaSiteId: string) =>
  window.open(`https://manage.wix.com/dashboard/${metaSiteId}/settings/business-info`)
