import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'

export enum RequestStatus {
  DEFAULT = 'DEFAULT',
  LOADING = 'LOADING',
  RESOLVED = 'RESOLVED',
  FAILED = 'FAILED',
}

type WrapRequestFunc = <T, K extends unknown[]>(
  flowAPI: ControllerFlowAPI,
  method: (flowApi: ControllerFlowAPI, ...params: K) => Promise<T>,
  dataFieldName: string,
  statusFieldName: string,
  errorFieldName?: string,
) => (...params: K) => Promise<T | undefined>

export const wrapRequest: WrapRequestFunc =
  (flowAPI, method, dataFieldName, statusFieldName, errorFieldName) =>
  async (...params) => {
    const setProps = flowAPI.controllerConfig.setProps

    try {
      setProps({
        [statusFieldName]: RequestStatus.LOADING,
      })

      const data = await method(flowAPI, ...params)
      setProps({
        [statusFieldName]: RequestStatus.RESOLVED,
        [dataFieldName]: data,
        ...(errorFieldName ? {[errorFieldName]: undefined} : {}),
      })

      return data
    } catch (err) {
      setProps({
        [statusFieldName]: RequestStatus.FAILED,
        ...(errorFieldName ? {[errorFieldName]: (err as any)?.response?.data} : {}),
      })
    }
  }
