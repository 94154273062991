import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'

export const setDataToMemoryCache = <T>(flowAPI: ControllerFlowAPI, key: string, data: T) => {
  try {
    flowAPI.controllerConfig.platformAPIs.storage.memory.setItem(key, JSON.stringify(data))
  } catch (e) {}
}

export const removeFromMemoryCache = (flowAPI: ControllerFlowAPI, key: string) => {
  try {
    flowAPI.controllerConfig.platformAPIs.storage.memory.removeItem(key)
  } catch (e) {}
}

export const getDataFromMemoryCache = <T>(flowAPI: ControllerFlowAPI, key: string): T | null => {
  try {
    const serializedData = flowAPI.controllerConfig.platformAPIs.storage.memory.getItem(key)

    if (serializedData) {
      return JSON.parse(serializedData) as T
    } else {
      return null
    }
  } catch (e) {
    return null
  }
}
